/* vim: set sw=4 ts=4 sts=4 expandtab : */

.mbdbv-emphasize {
    font-style: oblique;
}

div[class^="mbdbv-name-value-item-value"] > div[class^="mbdbv-name-value-waterfall"] {
    margin-left: 0.5rem;
    padding: 0 !important;
}

div[class^="mbdbv-name-value-waterfall"] > div[class^="mbdbv-name-value-item-value"] {
    margin-left: 0.5rem;
    padding: 0 !important;
}

div[class^="mbdbv-name-value-waterfall"] > div[class^="mbdbv-name-value-item-name"] {
    padding: 0 !important;
}

.mbdbv-name-value-waterfall {
    padding: 0 !important;
    margin-top: 0 !important;
}