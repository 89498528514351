@topMenuColor: rgba(13, 95, 137, 0.8);

header.oarepo {
    background-color: @topMenuColor;

    .ui.menu {
        border: none;
        box-shadow: none;
    }

    .ui.menu:not(.vertical) {
        background: none
    }

    .ui.menu:not(.vertical) > .menu {
        display: none;
    }
    .logo-text {
        font-size: 2rem;
        padding-top: 2rem;
    }
}

#skip-to-main {
    display: none;
}

.invenio-page-body {
    .ui.container.cover-page {
        background: none;
        border: none;
        box-shadow: none;
        height: auto;
    }
}

main {
    min-height: calc(100vh - 250px);
}